import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { useSocket } from "../hook/SocketContext";
import CanvasGame from "../Components/CanvasGame";
import {
    __game_get_ready,
    __game_ready,
    __game_update,
} from "../utils/socketEmits";
import Timer from "../Components/Timer";
import { SERVER_LINK } from "../ownEnv";
import { ReactComponent as BorderSvg } from "../svg/border.svg";
import { ReactComponent as ArrowSvg } from "../svg/arrow.svg";
import { ReactComponent as KrestSvg } from "../svg/krest.svg";
import EndGame from "../Components/Layout/EndGame";

/*
    load the game + 
    check user + 
    connect user to socket +
    w8 another one player +
    connect user users each other +
    aply game settings +-
    //start
    //trade game data p2p
    //send game data to server for check on valid
*/
export default function Game() {
    const urlqueries = useQuery();
    const [mobileView] = useState(urlqueries.get("mobile"));
    // let [searchParams, setSearchParams] = useSearchParams();

    const [gameData, setGameData] = useState({ game_state: 0 });

    const [firstStart, setFirstStart] = useState(false);
    const [startTheGame, setStartTheGame] = useState(false);
    const [endTheGame, setEndTheGame] = useState(false);
    const [lastMooveByTimeOut, setLastMoveByTimeOut] = useState(false);

    const [ready, setReady] = useState(false);
    const [readyState, setReadyState] = useState([false, false]);

    const [oponentSnake, setOponentSnake] = useState(false);
    const [reconnectMove, setReconnectMove] = useState(false);
    const [pauseGame, setPause] = useState(false);

    const [score, setScore] = useState(0);
    const [showOponentField, setShowOponentField] = useState(true);

    const { game, user } = useParams();
    const {
        connectToSocket,
        socket,
        peerBridge,
        // peerBridgeDisconnected,
        // setPeerBridgeDisconnected,
    } = useSocket();
    const peerBridgeDisconnected = false;

    const [showEndTheGame, setShowEndTheGame] = useState(false);
    // console.log(pauseGame);

    useEffect(() => {
        fetchGameData();
    }, []);
    useEffect(() => {
        if (+gameData?.game_state < 2) {
            if (socket && peerBridge) {
                goReady();
            }
        }
    }, [gameData, socket, peerBridge]);

    useEffect(() => {
        if (socket) {
            // socket.emit(__game_get_ready, gameData.id);
            socket.on(__game_get_ready, (data) => {
                setReadyState(data);
            });

            socket.on(__game_update, (updFameData) => {
                setGameData((prev) => {
                    return {
                        ...prev,
                        ...updFameData,
                    };
                });
            });
            socket.on("unpause_game", () => {
                setPause(false);
                setFirstStart(true);
            });
            socket.on("snake_after_pause", (data) => {
                // console.log("snake_after_pause");
                // console.log(data);
                setReconnectMove(data);
                setScore(data.score);

                if (data?.status === "end") {
                    setEndTheGame(true);
                    setPause(false);
                    // setFirstStart(true);
                }
            });
            socket.on("game_end_cheats", (data) => {
                console.log(data);
                if (user === data?.pidaras) {
                    alert(data.reason.for_cheater);
                } else {
                    alert(data.reason.for_winner);
                }
            });
            socket.on("game_end_status", (data) => {
                console.log("game_end_status");
                console.log(data);
                setGameData((prev) => ({ ...prev, ...data }));
                // setWinStatus(data)
            });
            socket.on("last_move_by_time_out", () => {
                setLastMoveByTimeOut(true);
            });

            return () => {
                socket.off(__game_get_ready);
                socket.off("unpause_game");
                socket.off("snake_after_pause");
                socket.off("game_end_status");
                socket.off("last_move_by_time_out");
            };
        }
    }, [socket]);
    useEffect(() => {
        if (!pauseGame && !endTheGame && firstStart) {
            console.log("go start after pause");
            // console.log(pauseGame, endTheGame, firstStart);
            setStartTheGame(true);
        }
    }, [pauseGame, endTheGame, firstStart]);

    useEffect(() => {
        if (peerBridge) {
            peerBridge.on("data", (data) => {
                // console.log("oponent move geted");
                setOponentSnake(data);
            });

            return () => {
                peerBridge.off("data");
            };
        }
    }, [peerBridge]);

    useEffect(() => {
        if (gameData && peerBridge) {
            if (
                gameData.game_state === 2 &&
                !readyState.includes(false) &&
                !firstStart
            ) {
                // console.log("start the first act game");
                setStartTheGame(true);
                setFirstStart(true);
            }
        }
        if (gameData) {
            if (
                gameData.game_state === 3 &&
                gameData?.winner !== undefined &&
                firstStart
            ) {
                console.log(gameData.winner);
                if (gameData.winner === "draw")
                    return setShowEndTheGame("DRAW");
                if (gameData.winner) {
                    setShowEndTheGame("win");
                } else {
                    // alert("you loose");
                    setShowEndTheGame("loose");
                }
            }
        }
    }, [gameData, peerBridge, readyState]);
    //reconected
    useEffect(() => {
        if (gameData && peerBridge) {
            if (
                gameData.game_state === 2 &&
                readyState.includes(false) &&
                !firstStart
            ) {
                // console.log("reconnect start");
                socket.emit("player_get_last_move");
                console.log("set pause line 152");
                setPause(true);

                //tyt polychaem last moove na prodoljenie
                //otschet i poehali
            }
        }
    }, [gameData, peerBridge, readyState]);

    //TO DO
    //pishem useeffect na pauzu esli vtoroi otlitel i jdem aproove chto chel gotov
    // posle otschet i poehali
    useEffect(() => {
        // first start change rules

        if (gameData && gameData.game_state === 2 && !peerBridge) {
            console.log("set pause line 165");
            setPause(true);
        }
    }, [gameData, peerBridge]);

    //if conn lost take lost

    // console.log(socket);
    // console.log(peerBridge);
    if (!gameData) return "Loading in game";
    if (!socket) return "Loading game connection";
    // console.log(gameData);

    if (gameData.game_state === 3 && !firstStart)
        return (
            <h1>
                Game {gameData.id} end. winner id: {gameData.winner}
            </h1>
        );
    return mobileView ? MobileView() : DeskView();

    function DeskView() {
        return (
            <div className="game-container desk">
                <EndGame status={showEndTheGame} />

                <div
                    className={`blured-end  ${
                        !!showEndTheGame ? "go-blur" : ""
                    }`}
                />
                <div className="my-game">
                    <div className="border l">
                        <BorderSvg />
                    </div>
                    <div className="my-field">
                        <div className="score">You: {score ?? 0}</div>

                        <div className="hldr-field">
                            {/* <EndGame status={showEndTheGame} /> */}
                            <CanvasGame
                                socket={socket}
                                gameId={gameData.id}
                                startTheGame={startTheGame}
                                setStartTheGame={setStartTheGame}
                                setScore={addScore}
                                score={score}
                                firstStart={firstStart}
                                peerConnect={peerBridge}
                                peerBridgeDisconnected={peerBridgeDisconnected}
                                gameBDState={gameData.game_state}
                                pause={pauseGame}
                                reconnectMove={reconnectMove}
                                setReconnectMove={setReconnectMove}
                                endTheGame={endTheGame}
                                setEndTheGame={setEndTheGame}
                                lastMooveByTimeOut={lastMooveByTimeOut}
                                settings={gameData.settings}
                            />
                        </div>

                        <div className="controls">
                            <div className="panel">
                                <div className="border">
                                    <KrestSvg />
                                </div>
                                <div className="btns">
                                    <div
                                        className="btn top"
                                        onClick={({ target }) => {
                                            goOwnKey(target, 87);
                                        }}
                                    >
                                        <ArrowSvg />
                                    </div>
                                    <div
                                        className="btn right"
                                        onClick={({ target }) =>
                                            goOwnKey(target, 68)
                                        }
                                    >
                                        <ArrowSvg />
                                    </div>
                                    <div
                                        className="btn bottom"
                                        onClick={({ target }) =>
                                            goOwnKey(target, 83)
                                        }
                                    >
                                        <ArrowSvg />
                                    </div>
                                    <div
                                        className="btn left"
                                        onClick={({ target }) =>
                                            goOwnKey(target, 65)
                                        }
                                    >
                                        <ArrowSvg />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="border r">
                        <BorderSvg />
                    </div>
                </div>

                <div className="oponent-field">
                    <div className="score">
                        Opponent: {oponentSnake.score ?? 0}
                    </div>
                    <div className="settings">
                        <span>Opponent screen</span>
                        <div
                            className={`btn-toggle ${
                                showOponentField ? "active" : ""
                            }`}
                            onClick={() => {
                                setShowOponentField((prev) => !prev);
                            }}
                        >
                            <div
                                className={`on ${
                                    showOponentField ? "tgl" : ""
                                }`}
                            >
                                ON
                            </div>
                            <div
                                className={`off ${
                                    showOponentField ? "" : "tgl"
                                }`}
                            >
                                OFF
                            </div>
                        </div>
                    </div>
                    <div className={`fld ${showOponentField ? "active" : ""}`}>
                        <CanvasGame
                            gameId={gameData.id}
                            settings={{ canvasSize: 350 }}
                            oponentSnake={oponentSnake}
                            watch
                        />
                    </div>
                </div>
            </div>
        );
    }

    function MobileView() {
        return (
            <div className="game-container mobik">
                {/* <div
                    className={`blured-end  ${
                        !!showEndTheGame ? "go-blur" : ""
                    }`}
                /> */}
                <EndGame status={showEndTheGame} />
                {/* <EndGame status={"win"} /> */}
                <div className="my-game">
                    <div className="mini-oponent">
                        <div className="left">
                            <div className="score">
                                You: <span> {score ?? 0}</span>
                            </div>
                            <div className="score">
                                Opponent:{" "}
                                <span>{oponentSnake.score ?? 0} </span>
                            </div>
                            <div className="set">
                                <span>Opponent screen</span>
                                <div
                                    className={`btn-toggle ${
                                        showOponentField ? "active" : ""
                                    }`}
                                    onClick={() => {
                                        setShowOponentField((prev) => !prev);
                                    }}
                                >
                                    <div
                                        className={`on ${
                                            showOponentField ? "tgl" : ""
                                        }`}
                                    >
                                        ON
                                    </div>
                                    <div
                                        className={`off ${
                                            showOponentField ? "" : "tgl"
                                        }`}
                                    >
                                        OFF
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div
                                className={`fld ${
                                    showOponentField ? "active" : ""
                                }`}
                            >
                                <CanvasGame
                                    gameId={gameData.id}
                                    settings={{ canvasSize: 350 }}
                                    oponentSnake={oponentSnake}
                                    watch
                                />
                            </div>
                        </div>
                    </div>
                    <div className="my-field">
                        {/* <div className="score">You: {score ?? 0}</div> */}

                        {/* <div className="hldr-field"> */}
                        <CanvasGame
                            socket={socket}
                            gameId={gameData.id}
                            startTheGame={startTheGame}
                            setStartTheGame={setStartTheGame}
                            setScore={addScore}
                            score={score}
                            firstStart={firstStart}
                            peerConnect={peerBridge}
                            peerBridgeDisconnected={peerBridgeDisconnected}
                            gameBDState={gameData.game_state}
                            pause={pauseGame}
                            reconnectMove={reconnectMove}
                            setReconnectMove={setReconnectMove}
                            endTheGame={endTheGame}
                            setEndTheGame={setEndTheGame}
                            lastMooveByTimeOut={lastMooveByTimeOut}
                            settings={gameData.settings}
                        />
                        {/* </div> */}
                        <div className="controls">
                            <div className="panel">
                                <div className="border">
                                    <KrestSvg />
                                </div>
                                <div className="btns">
                                    <div
                                        className="btn top"
                                        onClick={({ target }) => {
                                            goOwnKey(target, 87);
                                        }}
                                    >
                                        <ArrowSvg />
                                    </div>
                                    <div
                                        className="btn right"
                                        onClick={({ target }) =>
                                            goOwnKey(target, 68)
                                        }
                                    >
                                        <ArrowSvg />
                                    </div>
                                    <div
                                        className="btn bottom"
                                        onClick={({ target }) =>
                                            goOwnKey(target, 83)
                                        }
                                    >
                                        <ArrowSvg />
                                    </div>
                                    <div
                                        className="btn left"
                                        onClick={({ target }) =>
                                            goOwnKey(target, 65)
                                        }
                                    >
                                        <ArrowSvg />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function goOwnKey(target, key) {
        const customEvent = new CustomEvent("likekey", {
            detail: {
                name: "ownKey",
                keyCode: key,
            },
            bubbles: true,
        });
        // console.log(customEvent);
        target.dispatchEvent(customEvent);
    }

    /*
    <div className="couple-shit">
                <div className="oponent-game">
                    <CanvasGame
                        gameId={gameData.id}
                        settings={{ canvasSize: 250 }}
                        oponentSnake={oponentSnake}
                        watch
                    />
                </div>
                <div className="service">
                    <Timer
                        pause={pauseGame}
                        firstStartGame={firstStart}
                        gameBDState={gameData.game_state}
                    />
                    <br />
                    {pauseGame && (
                        <>
                            <p>pause</p>
                        </>
                    )}
                    {gameData.game_state < 2 ? (
                        ready ? (
                            <p>w8 oponent</p>
                        ) : (
                            ""
                        )
                    ) : (
                        <div className="socre">
                            <span>{score}</span>-
                            <span>{oponentSnake.score ?? 0}</span>
                        </div>
                    )}
                </div>
            </div>
            */
    function addScore(st = true) {
        if (st) {
            setScore((prev) => ++prev);
        }
    }
    function goReady() {
        socket.emit(__game_ready, gameData.id, !ready);
        setReady((prev) => !prev);
    }

    async function fetchGameData() {
        /*
            fetch init game
            get game data
            get token to socket connect
        */
        if (!game || !user) return alert("No data for game init");
        try {
            const { data } = await axios.get(
                `${SERVER_LINK}/api/usergame/init-game`,
                {
                    params: {
                        game,
                        user,
                    },
                }
            );
            setGameData(data.game);
            // if (data.game.game_state === 2) setFirstStart(true);
            connectToSocket(data.token);
            // console.log(data);
        } catch (error) {
            console.log(error);
            const msg = error.response.data.msg;
            alert(msg);
        }
    }
}

function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}
