import { useEffect, useRef } from "react";

// creating the custom useInterval hook
export function useInterval(callback, delay, depend = true) {
    // Creating a ref
    const savedCallback = useRef();
    // console.log(depend);

    // To remember the latest callback .
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // combining the setInterval and
    //clearInterval methods based on delay.
    useEffect(() => {
        if (depend) {
            function func() {
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(func, delay);
                return () => clearInterval(id);
            }
        }
    }, [delay, depend]);
}
