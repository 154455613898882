export default function ChangeDir(e, head) {
    let key = e.detail ? e.detail.keyCode : e.keyCode;
    // console.log(key);
    // console.log(e.detail);
    if (key === 68 || key === 39) {
        if (head.vX === -1) return;
        if (head.blockDirection) return;

        head.vX = 1;
        head.vY = 0;
        head.blockDirection = "y";
        // gameActive = true;
        return;
    }
    if (key === 65 || key === 37) {
        if (head.vX === 1) return;
        if (head.blockDirection) return;

        head.vX = -1;
        head.vY = 0;
        head.blockDirection = "y";

        // gameActive = true;
        return;
    }
    if (key === 87 || key === 38) {
        if (head.vY === 1) return;
        if (head.blockDirection) return;
        head.vX = 0;
        head.vY = -1;
        head.blockDirection = "x";
        // gameActive = true;
        return;
    }
    if (key === 83 || key === 40) {
        if (head.vY === -1) return;
        if (head.blockDirection) return;
        head.vX = 0;
        head.vY = 1;
        head.blockDirection = "x";
        // gameActive = true;
        return;
    }
}
