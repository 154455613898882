import { useState } from "react";

export default function useSnake({
    ctx,
    canvasSettings = { cellSize: 0, pGrid: 0 },
}) {
    const { cellSize, pGrid, cellCount, recursive } = canvasSettings;

    const [snakeParts, setSnakeParts] = useState([]);
    const [tailLength, setTailLength] = useState(canvasSettings.tailLength);

    const randomColor = () => {
        let color;
        let colorArr = ["#E6B9DE", "#E6B9DE"];
        color = colorArr[Math.floor(Math.random() * 2)];
        return color;
    };
    const [head] = useState({
        x: 2,
        y: 1,
        color: randomColor(),
        vX: 1,
        vY: 0,
        blockDirection: false,
        headImg: false,

        draw: (ctx, oponentx = { headImg: false, x: false, y: false }) => {
            const img = oponentx?.headImg ? oponentx?.headImg : head.headImg;
            const headX = oponentx.x ? oponentx.x : head.x;
            const headY = oponentx.y ? oponentx.y : head.y;
            if (!img) {
                ctx.beginPath();

                ctx.fillStyle = head.color;
                ctx.shadowColor = head.color;
                ctx.shadowBlur = 2.5;
                ctx.fillRect(
                    head.x * cellSize + pGrid,
                    head.y * cellSize + pGrid,
                    cellSize,
                    cellSize
                );
                ctx.closePath();
            } else {
                const imageSize = cellSize;
                const drawSize = cellSize + 0.5;
                const x = head.x * cellSize + pGrid;
                const y = head.y * cellSize + pGrid;

                const dirY = head.vY !== 0 ? (head.vY < 0 ? "up" : "down") : "";
                const dirX =
                    head.vX !== 0 ? (head.vX < 0 ? "left" : "right") : "";

                const direction = dirX ? dirX : dirY;
                return drawHead(ctx, {
                    headImg: head.headImg,
                    headX: head.x,
                    headY: head.y,
                    direction: direction,
                    cellSize: cellSize,
                    pGrid: pGrid,
                });
            }
        },
    });

    useState(() => {
        const headImg = new Image();
        headImg.src = "/snake_head.svg";
        headImg.onload = () => {
            head.headImg = headImg;
        };
    }, []);

    class Tail {
        static bodyImg = new Image();
        static tailImg = new Image();
        color = "#42f57e";
        constructor(x, y) {
            this.x = x;
            this.y = y;

            if (!Tail.bodyImg.src) {
                Tail.bodyImg.src = "/snake_body.svg";
            }
            if (!Tail.tailImg.src) {
                Tail.tailImg.src = "/snake_tail.svg";
            }
        }
        draw() {
            if (Tail.bodyImg?.src) {
                ctx.drawImage(
                    Tail.bodyImg,

                    this.x * cellSize + pGrid,
                    this.y * cellSize + pGrid,
                    cellSize + 0.5,
                    cellSize + 0.5
                );
            } else {
                ctx.fillStyle = this.color;
                ctx.shadowColor = this.color;
                ctx.shadowBlur = 2.5;
                ctx.fillRect(
                    this.x * cellSize + pGrid,
                    this.y * cellSize + pGrid,
                    cellSize,
                    cellSize
                );
            }
        }
        drawTail(preLastPos = { x: 0, y: 0 }) {
            if (Tail.tailImg?.src) {
                const imageSize = cellSize;
                const drawSize = cellSize + 0.5;
                const x = this.x * cellSize + pGrid;
                const y = this.y * cellSize + pGrid;

                let vX = this.x - preLastPos.x; // -1 - right, 0, 1 - left
                let vY = this.y - preLastPos.y; // -1 - down, 0, 1 - up
                if (vX > 1) vX = -1;
                if (vX < -1) vX = 1;
                if (vY < -1) vY = 1;
                if (vY > 1) vY = -1;
                const dirY = vY !== 0 ? (vY < 0 ? "down" : "up") : "";
                const dirX = vX !== 0 ? (vX < 0 ? "right" : "left") : "";
                const direction = dirX ? dirX : dirY;
                switch (direction) {
                    case "up":
                        // Ничего не делаем, картинка уже смотрит "вверх"
                        ctx.drawImage(
                            Tail.tailImg,

                            this.x * cellSize + pGrid,
                            this.y * cellSize + pGrid,
                            cellSize + 0.5,
                            cellSize + 0.5
                        );
                        break;
                    case "down":
                        ctx.translate(x + imageSize / 2, y + imageSize / 2);
                        ctx.rotate(Math.PI);
                        ctx.drawImage(
                            Tail.tailImg,
                            -imageSize / 2,
                            -imageSize / 2,
                            drawSize,
                            drawSize
                        );
                        break;
                    case "left":
                        ctx.translate(x + imageSize / 2, y + imageSize / 2);
                        ctx.rotate(-Math.PI / 2);
                        ctx.drawImage(
                            Tail.tailImg,
                            -imageSize / 2,
                            -imageSize / 2,
                            drawSize,
                            drawSize
                        );
                        break;
                    case "right":
                        ctx.translate(x + imageSize / 2, y + imageSize / 2);
                        ctx.rotate(Math.PI / 2);
                        ctx.drawImage(
                            Tail.tailImg,
                            -imageSize / 2,
                            -imageSize / 2,
                            drawSize,
                            drawSize
                        );
                        break;
                    default:
                        ctx.drawImage(
                            Tail.tailImg,

                            this.x * cellSize + pGrid,
                            this.y * cellSize + pGrid,
                            cellSize + 0.5,
                            cellSize + 0.5
                        );
                        break;
                }
                ctx.restore();
                ctx.setTransform(1, 0, 0, 1, 0, 0);
            } else {
                ctx.fillStyle = this.color;
                ctx.shadowColor = this.color;
                ctx.shadowBlur = 2.5;
                ctx.fillRect(
                    this.x * cellSize + pGrid,
                    this.y * cellSize + pGrid,
                    cellSize,
                    cellSize
                );
            }
        }
    }

    function updAfterPause(s_head, s_tail, status) {
        console.log(s_head);
        console.log(s_tail);
        console.log(status);
        const filledPos = [];

        setSnakeParts(() => {
            return s_tail.map((i) => {
                // if(searcheadX.includes(i.x)) nextAfterHeadX = i.x
                // if(searcheadY.includes(i.y)) nextAfterHeadY = i.y
                filledPos.push({ x: i.x, y: i.y });
                return new Tail(i.x, i.y);
            });
        });
        setTailLength(s_tail.length);

        // const allPosForNextMove = [
        //     { x: s_head.x, y: s_head.y - 1, tag: "vY", vY: -1 }, //back vY = -1
        //     { x: s_head.x, y: s_head.y + 1, tag: "vY", vY: 1 }, //forward vY = 1
        //     { x: s_head.x - 1, y: s_head.y, tag: "vX", vX: -1 }, //back vX = -1
        //     { x: s_head.x + 1, y: s_head.y, tag: "vX", vX: 1 }, //forward vX = 1
        // ].filter((i) => {
        //     if (i?.vY && i.vY < 0) {
        //         //top line
        //         if (i.y + i.vY < 0) return false;
        //         return true;
        //     }
        //     if (i?.vY && i.vY > 0) {
        //         //bottom line
        //         if (i.y + i.vY > cellCount - 1) return false;
        //         return true;
        //     }
        //     if (i?.vX && i.vX < 0) {
        //         //left line
        //         if (i.x + i.vX < 0) return false;
        //         return true;
        //     }
        //     if (i?.vX && i.vX > 0) {
        //         //right line
        //         if (i.x + i.vX > cellCount - 1) return false;
        //         return true;
        //     }

        //     return true;
        // });
        // const availablePositions = allPosForNextMove.filter((position) => {
        //     return !filledPos.some(
        //         (point) => point.x === position.x && point.y === position.y
        //     );
        // });
        // let nextMove =
        //     availablePositions.length > 0
        //         ? availablePositions[0]
        //         : { tag: "vX", vX: 1 };

        head.x = s_head.x;
        head.y = s_head.y;
        head.vX = 0;
        head.vY = 0;
        if (status !== "end") {
            // head[nextMove.tag] = nextMove[nextMove.tag];
            if (s_head.vX) head.vX = s_head.vX;
            if (s_head.vY) head.vY = s_head.vY;
        }
        // vY: 0, = s_head.y
    }

    function updTailForDraw(tail) {
        return tail.map((i) => new Tail(i.x, i.y));
    }
    function updHeadForDraw(headPos) {
        const newColor = randomColor();

        const dirY = headPos.vY !== 0 ? (headPos.vY < 0 ? "up" : "down") : "";
        const dirX =
            headPos.vX !== 0 ? (headPos.vX < 0 ? "left" : "right") : "";

        const direction = dirX ? dirX : dirY;

        return {
            ...headPos,
            draw: (ctx) => {
                if (head.headImg) {
                    drawHead(ctx, {
                        headImg: head.headImg,
                        headX: headPos.x,
                        headY: headPos.y,
                        direction: direction,
                        cellSize: cellSize,
                        pGrid: pGrid,
                    });
                } else {
                    ctx.beginPath();

                    ctx.fillStyle = newColor;
                    ctx.shadowColor = newColor;
                    ctx.shadowBlur = 2.5;
                    ctx.fillRect(
                        head.x * cellSize + pGrid,
                        head.y * cellSize + pGrid,
                        cellSize,
                        cellSize
                    );
                    ctx.closePath();
                }
            },
        };
    }

    const updateSnakePosition = () => {
        let newX = head.x + head.vX;

        let newY = head.y + head.vY;
        if (recursive) {
            if (newX < 0) {
                newX = cellCount - 1;
            }
            if (newX >= cellCount) {
                newX = 0;
            }
            if (newY < 0) {
                newY = cellCount - 1;
            }
            if (newY >= cellCount) {
                newY = 0;
            }
        }

        head.x = newX;
        head.y = newY;
        head.blockDirection = false;
    };
    const snakePos = () => {
        //loop through our snakeparts array

        // snakeParts.forEach((part) => {
        //     part.draw();
        // });

        snakeParts.push(new Tail(head.x, head.y));

        if (snakeParts.length > tailLength) {
            snakeParts.shift(); //remove furthest item from  snake part if we have more than our tail size
        }
        head.color = randomColor();
        return {
            head,
            tail: snakeParts,
        };
        // headState.draw();
    };

    function addTail() {
        setTailLength((prev) => ++prev);
        // snakePos();
    }
    function resetSnake() {
        head.x = 2;
        head.y = 1;
        head.vX = 1;
        head.vY = 0;
        setSnakeParts([]);
        setTailLength(canvasSettings.tailLength);
    }
    return {
        addTail,
        snakePos,
        updateSnakePosition,
        resetSnake,
        updTailForDraw,
        updHeadForDraw,
        updAfterPause,
    };
}

function drawHead(
    ctx,
    {
        headX = false,
        headY = false,
        headImg = false,
        direction = "right",
        cellSize = 10,
        pGrid = 0,
    }
) {
    const imageSize = cellSize;
    const drawSize = cellSize + 0.5;
    const x = headX * cellSize + pGrid;
    const y = headY * cellSize + pGrid;

    // const direction = dirX ? dirX : dirY;
    switch (direction) {
        case "up":
            // Ничего не делаем, картинка уже смотрит "вверх"
            ctx.drawImage(
                headImg,

                x,
                y,
                drawSize,
                drawSize
            );
            break;
        case "down":
            ctx.translate(x + imageSize / 2, y + imageSize / 2);
            ctx.rotate(Math.PI);
            ctx.drawImage(
                headImg,
                -imageSize / 2,
                -imageSize / 2,
                drawSize,
                drawSize
            );
            break;
        case "left":
            ctx.translate(x + imageSize / 2, y + imageSize / 2);
            ctx.rotate(-Math.PI / 2);
            ctx.drawImage(
                headImg,
                -imageSize / 2,
                -imageSize / 2,
                drawSize,
                drawSize
            );
            break;
        case "right":
            ctx.translate(x + imageSize / 2, y + imageSize / 2);
            ctx.rotate(Math.PI / 2);
            ctx.drawImage(
                headImg,
                -imageSize / 2,
                -imageSize / 2,
                drawSize,
                drawSize
            );
            break;
        default:
            ctx.drawImage(
                headImg,

                x,
                y,
                drawSize,
                drawSize
            );
            break;
    }
    ctx.restore();
    ctx.setTransform(1, 0, 0, 1, 0, 0);
}
