import { Route, Routes } from "react-router-dom";
import Game from "./Pages/Game";
// import { useEffect } from "react";

function App() {
    // useEffect(() => {
    //     console.log(
    //         `inner sizes (WxH) ${window.innerWidth}x${window.innerHeight}`
    //     );
    //     console.log(
    //         `outer sizes (WxH) ${window.outerWidth}x${window.outerWidth}`
    //     );
    // }, []);
    return (
        <Routes>
            <Route path="/:game/:user" element={<Game />} />
            <Route path="*" element={<h1>Not auth for game</h1>} />
        </Routes>
    );
}

export default App;
