// export default food = {
//     food_state: {

import { useEffect, useState } from "react";

export default function useFood({
    ctx,
    defaultFoodColor = "#FF3131",
    canvasSettings = { cellSize: 0, pGrid: 0, cellCount: 0 },
}) {
    const { cellSize, cellCount } = canvasSettings;
    // console.log(cellCount);
    const [food, setDefaultFood] = useState({
        x: Math.floor(Math.random() * (cellCount - 1)),
        y: Math.floor(Math.random() * (cellCount - 1)),
        // x: 8,
        // y: 1,
        color: defaultFoodColor,
        radius: cellSize / 2,
        foodImg: false,
        foodImgWidth: 0,
        foodImgHeight: 0,
    });
    useEffect(() => {
        const foodImg = new Image();
        foodImg.src = "/food.svg";
        foodImg.onload = () => {
            var foodImgWidth = foodImg.width;
            var foodImgHeight = foodImg.height;
            // console.log(foodImgWidth, foodImgHeight);
            setDefaultFood((prev) => ({
                ...prev,
                foodImg,
                foodImgWidth,
                foodImgHeight,
            }));
        };
    }, []);
    // const radius = cellSize / 2;

    function setFood(params = { x: food.x, y: food.y }) {
        setDefaultFood((prev) => ({
            ...prev,
            ...params,
        }));
    }
    function getFood() {
        return food;
        // return { x: food.x, y: food.y };
    }
    function getFoodForDraw(f) {
        return {
            ...food,
            ...f,
        };
    }

    // const foodCollision = () => {
    //     let foodCollision = false;
    //     snakeParts.forEach((part) => {
    //         if (part.x == food.x && part.y == food.y) {
    //             foodCollision = true;
    //         }
    //     });
    //     if (foodCollision) {
    //         food.x = Math.floor(Math.random() * cellCount);
    //         food.y = Math.floor(Math.random() * cellCount);
    //         score++;
    //         tailLength++;
    //     }
    // };

    return { setFood, getFood, getFoodForDraw };
}
