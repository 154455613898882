import { useState } from "react";

export default function UseCanvas({ ctx, canvasSettings }) {
    const {
        canvasFillColor,
        canvasStrokeColor,
        canvasSize,
        grid_line_len,
        cellSize,
        pGrid,
        cellCount,
    } = canvasSettings;
    const [lastFoodPos, setLastFoodPos] = useState({ x: null, y: null });
    const [lastSnakePos, setLastSnakePos] = useState({
        head: null,
        tail: null,
    });

    function DrawCanvas({
        showGrid,
        food = lastFoodPos,
        snake = lastSnakePos,
        ready = false,
        pause = false,
    }) {
        initCanvas(ctx);
        if (showGrid) {
            drawGrid(ctx);
        }
        if (ready) {
            let c_width = ctx.canvas.width;
            let c_height = ctx.canvas.height;

            ctx.font = "74px DatCub";

            var textWidth = ctx.measureText(ready).width;
            var textHeight = ctx.measureText("M").width; // Приближенное значение высоты символа
            var x = (c_width - textWidth) / 2;
            var y = (c_height + textHeight) / 2;
            // ctx.fillStyle = "red";
            // ctx.fillRect(x, y - textHeight, textWidth, textHeight);

            ctx.fillStyle = "#353933";
            ctx.strokeStyle = "#A5CC99";
            // ctx.strokeWidth = "2";
            ctx.lineWidth = 1;
            ctx.textBaseline = "middle";
            ctx.fillText(ready, x, y);
            // ctx.font = "64px DatCub";
            // ctx.strokeText(ready, x, y);
        } else {
            if (food.x !== null && food.y !== null) {
                setLastFoodPos(food);
                drawFood(ctx, food);
            }
            if (snake.head && snake.tail) {
                setLastSnakePos(snake);
                drawSnake(ctx, snake);
            }

            if (pause) {
                let c_width = ctx.canvas.width;
                let c_height = ctx.canvas.height;
                ctx.font = "74px DatCub";
                var textWidth = ctx.measureText(ready).width;
                var textHeight = ctx.measureText("M").width; // Приближенное значение высоты символа
                var x = (c_width - textWidth) / 2;
                var y = (c_height + textHeight) / 2;
                ctx.fillStyle = "#353933";
                ctx.strokeStyle = "#A5CC99";
                ctx.lineWidth = 1;
                ctx.textBaseline = "middle";
                ctx.fillText("Pause", x, y);
                // ctx.font = "64px DatCub";
                // ctx.strokeText("Pause", x, y);
            }
        }
    }

    return DrawCanvas;

    function initCanvas(ctx) {
        const w = canvasSize;
        const h = canvasSize;
        // canvas fill

        // ctx.clearRect(0, 0, w, h);
        ctx.fillStyle = canvasFillColor;
        ctx.fillRect(0, 0, w, h);
        // canvas stroke
        ctx.strokeStyle = canvasStrokeColor;
        ctx.strokeRect(0, 0, w, h);
        drawColor(ctx);
    }

    function drawColor(ctx) {
        const colors = ["#A4CC98", "#ADD3A2"];
        let colorIndex = false;
        for (let y = 0; y <= cellCount; y += 1) {
            for (let x = 0; x <= cellCount; x += 1) {
                ctx.fillStyle = colors[+colorIndex];
                colorIndex = !colorIndex;

                ctx.fillRect(
                    // x * cellSize + pGrid,
                    // y * cellSize + pGrid,
                    x * cellSize,
                    y * cellSize,
                    cellSize + 1,
                    cellSize + 1
                );
                ctx.fill();
            }
            if (cellCount % 2 !== 0) {
                colorIndex = !colorIndex;
            }
        }
    }

    function drawGrid(ctx) {
        ctx.beginPath();
        for (let i = 0; i <= grid_line_len; i += cellSize) {
            ctx.moveTo(i + pGrid, pGrid);
            ctx.lineTo(i + pGrid, grid_line_len + pGrid);
        }
        for (let i = 0; i <= grid_line_len; i += cellSize) {
            ctx.moveTo(pGrid, i + pGrid);
            ctx.lineTo(grid_line_len + pGrid, i + pGrid);
        }
        ctx.closePath();
        ctx.strokeStyle = canvasStrokeColor;
        ctx.stroke();
    }

    function drawFood(ctx, food) {
        const { color, radius, x, y, foodImg, foodImgWidth, foodImgHeight } =
            food;
        if (foodImg) {
            ctx.drawImage(
                foodImg,

                x * cellSize + pGrid,
                y * cellSize + pGrid,
                cellSize,
                cellSize
            );
        } else {
            //Esli net food image
            ctx.fillStyle = color;
            ctx.shadowColor = color;
            ctx.shadowBlur = 5;
            ctx.fillRect(
                x * cellSize + pGrid,
                y * cellSize + pGrid,
                cellSize,
                cellSize
            );
            ctx.beginPath();
            ctx.ellipse(
                x * cellSize + pGrid + radius,
                y * cellSize + pGrid + radius,
                // food.x * cellSize + pGrid + radius,
                // food.y * cellSize + pGrid + radius,
                radius,
                radius,
                0,
                0,
                180
            );
            ctx.closePath();
        }
    }
    function drawSnake(ctx, snake) {
        const { head, tail } = snake;

        head.draw(ctx);
        tail.forEach((part, idx, arr) => {
            if (idx === 0) {
                part.drawTail(arr[idx + 1]);
            } else {
                part.draw();
            }
        });
    }
}
