import { createContext, useContext, useEffect, useState } from "react";
import * as socketIO from "socket.io-client";
import { Peer } from "peerjs";
import {
    PEER_IP,
    PEER_PATH,
    PEER_PORT,
    SERVER_LINK,
    SERVER_URL,
} from "../ownEnv";
import { generateKey } from "../utils";

const intialData = {
    socket: undefined,
    user: null,
};

const SocketContext = createContext(intialData);

export function useSocket() {
    return useContext(SocketContext);
}

export default function SocketProvider({ children }) {
    const [socket, setSocket] = useState();
    const [peerId] = useState(generateKey(8));

    const [peer, setPeer] = useState(false);
    const [peerBridge, setPeerBridge] = useState(false);
    const [peerBridgeDisconnected, setPeerBridgeDisconnected] = useState(false);

    // const [connectedToRoom, setConnectedToRoom] = useState(false)
    const [oponentConnected_Status, setOponentConnected_Status] =
        useState(false);

    useEffect(() => {
        if (socket) {
            socket.on("oponent_connected", (data) => {
                console.log("Users in room", data);
                setOponentConnected_Status(data >= 2);
                if (data < 2) {
                    setPeerBridge(false);
                }
            });

            socket.on("oponent_disc", () => {
                console.log("socket on dickonnect");
                setPeerBridge(false);
            });
            socket.on("error", (err) => {});
            return () => {
                socket.off("error");
                socket.off("room_users");
                socket.off("oponent_disc");
                socket.off("oponent_connected");
                socket.off("connect_to_peer");
                // socket.disconnect(user);
            };
        }
    }, [socket]);
    //
    useEffect(() => {
        // console.log(oponentConnected_Status);
        if (oponentConnected_Status && !peer) {
            console.log("go connect peer js");
            // console.log("connect with STUN googl, TURN: none");
            // console.log("connect withOUT STUN TURN");
            // console.log("connect with STUN & TURN TWILIO");
            const newPeer = new Peer(peerId, {
                // host: "localhost",
                host: PEER_IP,
                // port: PEER_PORT,
                // path: PEER_PATH,
                // host: location.hostname,
                secure: true,
                port: "443",
                path: "peerjs",
                debug: 1,
                config: {
                    // iceServers: [
                    //     {
                    //         url: "stun:91.228.154.129:3478",
                    //         urls: "stun:91.228.154.129:3478",
                    //     },
                    //     {
                    //         url: "turn:turn.playroom.network:3478",
                    //         username: "userturntest",
                    //         urls: "turn.playroom.network:3478",
                    //         credential: "FgjsdfngsFfjdjdjdje564378ujesfkfj",
                    //     },
                    //     {
                    //         url: "turn:turn.playroom.network:443",
                    //         username: "userturntest",
                    //         urls: "turn.playroom.network:443",
                    //         credential: "FgjsdfngsFfjdjdjdje564378ujesfkfj",
                    //     },
                    //     // { urls: "turn:your-turn-server.com:3478", username: "your-username", credential: "your-password" }
                    // ],

                    iceServers: [
                        {
                            url: "stun:global.stun.twilio.com:3478",
                            urls: "stun:global.stun.twilio.com:3478",
                        },
                        {
                            url: "turn:global.turn.twilio.com:3478?transport=udp",
                            username:
                                "d7a211c3c0210c5ac5ed51c50f7553284172be8430f9da684e52c9addca9ecca",
                            urls: "turn:global.turn.twilio.com:3478?transport=udp",
                            credential:
                                "iCtYXKi8t1EiaFElCt9TZQl8RZBq2B9BZoE/EjUvZVM=",
                        },
                        {
                            url: "turn:global.turn.twilio.com:3478?transport=tcp",
                            username:
                                "d7a211c3c0210c5ac5ed51c50f7553284172be8430f9da684e52c9addca9ecca",
                            urls: "turn:global.turn.twilio.com:3478?transport=tcp",
                            credential:
                                "iCtYXKi8t1EiaFElCt9TZQl8RZBq2B9BZoE/EjUvZVM=",
                        },
                        {
                            url: "turn:global.turn.twilio.com:443?transport=tcp",
                            username:
                                "d7a211c3c0210c5ac5ed51c50f7553284172be8430f9da684e52c9addca9ecca",
                            urls: "turn:global.turn.twilio.com:443?transport=tcp",
                            credential:
                                "iCtYXKi8t1EiaFElCt9TZQl8RZBq2B9BZoE/EjUvZVM=",
                        },
                    ],
                },
            });
            // console.log(newPeer);
            setPeer(newPeer);
            console.log(`peer created, id sended: ${peerId}`);
            // socket.emit("player_peer_id", peerId);
        }
    }, [oponentConnected_Status]);
    useEffect(() => {
        if (peer) {
            peer.on("connection", (conn) => {
                setPeerBridge(conn);
                console.log(`${conn.peer} connected to you`);
                // if (peerBridgeDisconnected) {
                //     socket.emit("send_last_move_conn");
                // }
                // setPeerBridgeDisconnected(false);
            });
            peer.on("open", (d) => {
                console.log("peer open");
                console.log(d);
                socket.emit("player_peer_id", d);
            });
            peer.on("disconnected", (conn) => {
                console.log("Peer disconnected: ", conn.id);
            });
            peer.on("error", (data) => {
                console.log("Peer err: ");
            });
            peer.on("close", (data) => {
                console.log("Peer close: ");
            });

            socket.on("connect_to_peer", (toPeer) => {
                if (toPeer === peerId) return;
                console.log(`my peer: ${peerId}, another peer ${toPeer}`);
                console.log("connecting to ", toPeer);
                const newPeerBridge = peer.connect(toPeer);
                setPeerBridge(newPeerBridge);
                // if (peerBridgeDisconnected) {
                //     socket.emit("send_last_move_conn");
                // }
                // setPeerBridgeDisconnected(false);
            });
            return () => {
                peer.off("connection");
                peer.off("close");
                peer.off("error");
                peer.off("disconnected");
                socket.off("connect_to_peer");
            };
        }
    }, [peer]);
    useEffect(() => {
        if (peerBridge) {
            peerBridge.on("open", () => {
                console.log("Bridge connected!");
            });
            peerBridge.on("disconnected", (data) => {
                console.log("PeerBridge disconnected: ", peerBridge.id);
            });
            peerBridge.on("error", (data) => {
                console.log("PeerBridge err: ");
            });
            peerBridge.on("close", (data) => {
                console.log("PeerBridge close: ", peerBridge.peer);
                // setPeerBridgeDisconnected(true);
                setPeerBridge(false);
            });

            return () => {
                peerBridge.off("open");
                peerBridge.off("disconnected");
                peerBridge.off("error");
                peerBridge.off("close");
            };
        }
    }, [peerBridge]);

    return (
        <SocketContext.Provider
            value={{
                socket,
                connectToSocket,
                oponentConnected_Status,
                peer,
                peerBridge,
                peerBridgeDisconnected,
                setPeerBridgeDisconnected,
            }}
        >
            {children}
        </SocketContext.Provider>
    );

    function connectToSocket(token) {
        let socket = socketIO.connect(SERVER_URL, {
            extraHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
        socket.emit("player_auth_to_room");
        setSocket(socket);
    }
}
