export const __auth = "auth";
export const __user_data = "auth_player_info";
export const __game_list = "auth_game_list";
export const __game_create = "auth_game_create";
export const __game_enter = "auth_game_enter";
export const __game_leave = "auth_game_leave";
export const __game_update = "upd_game";
export const __game_ready = "auth_game_ready";
export const __game_get_ready = "auth_ready_state";
export const __game_save_pos = "auth_game_save_pos";
export const __game_anticheat = "auth_game_anticheat";
export const __game_oponent_move = "oponent_move";

export const __my_ID = "auth_my_id";

// export const __auth: 'auth';
