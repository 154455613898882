import { Player, Controls } from "@lottiefiles/react-lottie-player";
import draw from "./end_anim/draw_snake.json";
import win from "./end_anim/win_snake.json";
import loose from "./end_anim/loss_snake.json";
import { useEffect, useRef, useState } from "react";

export default function EndGame({ status = false }) {
    // const [status, setSt] = useState("win");
    const animations = {
        win: win,
        loose: loose,
        draw: draw,
    };
    const ref = useRef(null);
    const [ready, setReady] = useState(false);
    const [toShow, setToShow] = useState(false);

    useEffect(() => {
        setReady(false);
        if (status) setToShow(animations[status.toLowerCase()]);
    }, [status]);

    useEffect(() => {
        if (!!ref.current && ready && !!toShow) {
            ref.current.play();
        } else {
            ref.current.setSeeker(0);
        }
    }, [toShow, ref.current, ready]);

    // const toShow = status ? animations[status.toLowerCase()] : <></>;
    // console.log(status, ready);
    // console.log(toShow);
    // console.log(ref.current);

    return (
        <div className={`end-game-animation ${status ? "active" : ""}`}>
            {/* <Lottie animationData={toShow} loop={true} /> */}
            <Player
                autoplay={false}
                onEvent={(ev) => {
                    if (ev === "load" && status) setReady(true);
                    // if (ev === "loop") ref.current.pause();
                }}
                loop={false}
                keepLastFrame
                className="aboba"
                ref={ref}
                src={toShow}
            >
                <Controls
                    visible={false}
                    buttons={["play", "repeat", "frame", "debug"]}
                />
            </Player>
        </div>
    );
}
