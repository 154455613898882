import { useEffect, useRef, useState } from "react";
import { useInterval } from "../../hook/useInterval";
import useFood from "./utils/food";
import UseCanvas from "./utils/field";
import useSnake from "./utils/snake";
import ChangeDir from "./utils/moovControls";
import { __game_anticheat, __game_save_pos } from "../../utils/socketEmits";

/*
settings: {
    grid: true || false
    canvasSize
    recursive,
    frameRate
}
*/
export default function CanvasGame({
    startTheGame = false,
    setStartTheGame = () => {},
    socket,
    peerConnect,
    peerBridgeDisconnected,

    gameId = "",
    watch = false,
    oponentSnake = false,
    settings = {},
    setScore = () => {},
    score = 0,
    gameBDState = 0,

    firstStart,
    pause,

    reconnectMove,
    setReconnectMove,
    endTheGame,
    setEndTheGame,
    lastMooveByTimeOut,
}) {
    const [gameStartCounter, setGameStartCounter] = useState(3);

    const [ctx, setContext] = useState(null);
    const canvasRef = useRef(null);

    const [gameState, setGameState] = useState(false);
    // const [endGameState, setEndGameState] = useState(null);
    const [winGameState, setWinGameState] = useState(null);

    const [showGrid, setShowGrid] = useState(settings?.grid || false);

    const [playgroundSettings] = useState({
        canvasSize: settings?.canvasSize || 440,
        canvasFillColor: "#000d36",
        canvasStrokeColor: "rgba(211, 211, 211, 0.19)",
        pGrid: 0,
        cellCount: 20,
    });
    const [gameSettings] = useState({
        frameRate: settings?.frameRate || 12,
        tailLength: 2,
        recursive: settings?.recursive ?? true,
    });
    const [[grid_line_len, cellSize]] = useState(() => {
        const grid_line_len =
            playgroundSettings.canvasSize - 2 * playgroundSettings.pGrid;
        // console.log(grid_line_len);
        // cell size
        const cellSize = grid_line_len / playgroundSettings.cellCount;
        console.log(cellSize);
        return [grid_line_len, cellSize];
    });

    //RISUEM TYT
    const DrawCanvas = UseCanvas({
        ctx,
        canvasSettings: {
            ...playgroundSettings,
            grid_line_len,
            cellSize,
        },
        showGrid,
    });

    //YPRAVLENIE EDOI
    const { setFood, getFood, getFoodForDraw } = useFood({
        ctx,
        canvasSettings: { cellSize, ...playgroundSettings },
    });
    // ZMEYOI
    const {
        addTail,
        snakePos,
        updateSnakePosition,
        resetSnake,
        updTailForDraw,
        updHeadForDraw,
        updAfterPause,
    } = useSnake({
        ctx,
        canvasSettings: { cellSize, ...playgroundSettings, ...gameSettings },
    });

    useEffect(() => {
        if (reconnectMove) {
            console.log("reconnectMove: ");
            console.log(reconnectMove);
            updAfterPause(
                reconnectMove.head,
                reconnectMove.tail,
                reconnectMove?.status
            );
            setReconnectMove(false);
        }
    }, [reconnectMove]);

    useEffect(() => {
        //Draw on canvas each time
        setContext(canvasRef.current && canvasRef.current.getContext("2d")); //store in state variable
        if (ctx) {
            DrawCanvas({ showGrid });
            const snake = snakePos();
            window.addEventListener("keydown", (e) => {
                ChangeDir(e, snake.head);
            });
            window.addEventListener("likekey", (e) => {
                ChangeDir(e, snake.head);
            });
        }
        // eslint-disable-next-line
    }, [ctx]);
    useEffect(() => {
        if (ctx && !gameState) {
            DrawCanvas({ showGrid });
        }
        // eslint-disable-next-line
    }, [showGrid]);

    useEffect(() => {
        if (lastMooveByTimeOut) {
            setGameState(false);

            const food = getFood();
            const snake = snakePos();
            const dateForServ = {
                head: {
                    x: snake.head.x,
                    y: snake.head.y,
                },
                tail: snake.tail.map((i) => ({ x: i.x, y: i.y })),
                food: {
                    x: food.x,
                    y: food.y,
                },
                score: score,
                status: "end",
            };

            socket.emit(__game_save_pos, { ...dateForServ, gameId });
        }
    }, [lastMooveByTimeOut]);
    //START GAME
    useInterval(
        () => {
            const food = getFood();
            const snake = snakePos();

            foodCollision(snake, food);
            updateSnakePosition();

            const dateForServ = {
                head: {
                    x: snake.head.x,
                    y: snake.head.y,
                    vX: snake.head.vX,
                    vY: snake.head.vY,
                },
                tail: snake.tail.map((i) => ({ x: i.x, y: i.y })),
                food: {
                    x: food.x,
                    y: food.y,
                },
                score: score,
            };

            peerConnect.send(dateForServ);
            socket.emit(__game_save_pos, { ...dateForServ, gameId });
            socket.emit(__game_anticheat, { ...dateForServ, gameId });

            DrawCanvas({ showGrid, food, snake });
            checkEndGame(snake);
        },
        1000 / gameSettings.frameRate,
        gameState && !watch && peerConnect
    );

    useEffect(() => {
        if (watch && oponentSnake) {
            const { head, tail, food } = oponentSnake;
            // if (head.x && head.y) {
            DrawCanvas({
                showGrid: false,
                food: getFoodForDraw(food),
                snake: {
                    head: updHeadForDraw(head),
                    tail: updTailForDraw(tail),
                },
            });
            // }
        }
        // eslint-disable-next-line
    }, [watch, oponentSnake]);

    useEffect(() => {
        if (pause) {
            DrawCanvas({
                pause: true,
                // food,
                // snake,
            });
            // setStartTheGame(true);
            setGameState(false);
        }
    }, [pause]);
    useEffect(() => {
        // console.log(reconnectMove);
        // console.log(endTheGame);
        if (endTheGame && !reconnectMove) {
            const food = getFood();
            const snake = snakePos();

            DrawCanvas({
                pause: false,
                food,
                snake,
                showGrid,
            });
        }
    }, [endTheGame, reconnectMove]);

    useInterval(
        () => {
            if (gameStartCounter > 0) {
                DrawCanvas({
                    ready: gameStartCounter,
                });
                setGameStartCounter((prev) => --prev);
            } else {
                setGameStartCounter(3);
                setStartTheGame(false);
                setGameState(true);
            }
        },
        1000,
        startTheGame
    );

    return (
        <>
            {/* {startTheGame && gameStartCounter > 0 && (
                <h1>НАчало через: {gameStartCounter}</h1>
            )} */}
            <canvas
                ref={canvasRef}
                width={playgroundSettings.canvasSize}
                height={playgroundSettings.canvasSize}
            />
        </>
    );

    function checkEndGame(snake) {
        // let stopGame = false;
        // let winGame = false;
        // console.log(snake);
        if (
            snake.tail.length + 1 ===
            Math.pow(playgroundSettings.cellCount, 2)
        ) {
            // stopGame = true;
            // winGame = true;
            setGameState(false);
            // setEndGameState("end");
            // setWinGameState(true);
            setEndTheGame(true);

            return;
        }

        if (!gameSettings.recursive) {
            // console.log("check head");

            const checkXcord =
                snake.head.x < 0 ||
                snake.head.x >= playgroundSettings.cellCount;
            const checkYcord =
                snake.head.y < 0 ||
                snake.head.y >= playgroundSettings.cellCount;
            // console.log(`x: ${checkXcord}`);
            // console.log(`y: ${checkYcord}`);
            if (checkXcord) {
                setGameState(false);
                // setEndGameState("end");
                // setWinGameState(false); // TEMP, MUST BE FROM SERVER

                // setEndTheGame(true);  test off
                const food = getFood();
                const snake = snakePos();
                const dateForServ = {
                    head: {
                        x: snake.head.x,
                        y: snake.head.y,
                    },
                    tail: snake.tail.map((i) => ({ x: i.x, y: i.y })),
                    food: {
                        x: food.x,
                        y: food.y,
                    },
                    score: score,
                    status: "end",
                };
                socket.emit(__game_save_pos, { ...dateForServ, gameId });
                return;
            }
            if (checkYcord) {
                setGameState(false);
                // setEndGameState("end");
                // setWinGameState(false); // TEMP, MUST BE FROM SERVER

                // setEndTheGame(true);  test off
                const food = getFood();
                const snake = snakePos();
                const dateForServ = {
                    head: {
                        x: snake.head.x,
                        y: snake.head.y,
                    },
                    tail: snake.tail.map((i) => ({ x: i.x, y: i.y })),
                    food: {
                        x: food.x,
                        y: food.y,
                    },
                    score: score,
                    status: "end",
                };
                socket.emit(__game_save_pos, { ...dateForServ, gameId });
                return;
            }
        }

        for (let tail of snake.tail) {
            // console.log(tail);
            // console.log(tail.x, snake.head.x);
            if (tail.x === snake.head.x && tail.y === snake.head.y) {
                setGameState(false);
                // setEndGameState("end");
                // setWinGameState(false); // TEMP, MUST BE FROM SERVER

                const food = getFood();
                const snake = snakePos();
                const dateForServ = {
                    head: {
                        x: snake.head.x,
                        y: snake.head.y,
                    },
                    tail: snake.tail.map((i) => ({ x: i.x, y: i.y })),
                    food: {
                        x: food.x,
                        y: food.y,
                    },
                    score: score,
                    status: "end",
                };
                socket.emit(__game_save_pos, { ...dateForServ, gameId });
                setEndTheGame(true);
                break;
                // return
            }
        }
    }

    // HAVAEM PIZZU
    function foodCollision(snake, food) {
        let foodCollision = false;
        const filledPos = [{ x: snake.head.x, y: snake.head.y }];
        // const filledPos = new Set([`${snake.head.x}_${snake.head.y}`]);

        if (snake.head.x === food.x && snake.head.y === food.y) {
            foodCollision = true;
        }
        snake.tail.forEach((part) => {
            if (part.x === food.x && part.y === food.y) {
                foodCollision = true;
            }

            filledPos.push({ x: part.x, y: part.y });
        });

        if (foodCollision) {
            function getNewPosition(
                existingPoints,
                fieldSize = playgroundSettings.cellCount
            ) {
                const allPositions = [];

                for (let x = 0; x < fieldSize; x++) {
                    for (let y = 0; y < fieldSize; y++) {
                        allPositions.push({ x, y });
                    }
                }
                const availablePositions = allPositions.filter((position) => {
                    return !existingPoints.some(
                        (point) =>
                            point.x === position.x && point.y === position.y
                    );
                });

                if (availablePositions.length > 0) {
                    const randomIndex = Math.floor(
                        Math.random() * availablePositions.length
                    );
                    return availablePositions[randomIndex];
                }

                return null;
            }

            const { x: newX, y: newY } = getNewPosition(filledPos);

            setScore(true);
            setFood({
                x: newX,
                y: newY,
            });
            addTail();
            // score++;
            // tailLength++;
        }
    }
}
