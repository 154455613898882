// export const SERVER_LINK = "http://localhost:1488";
// export const SERVER_URL = "http://localhost:1488";
// export const PEER_IP = "localhost";
// export const PEER_PORT = "1489";
// export const PEER_PATH = "/pr";
// export const SERVER_LINK = "https://test.ukmiko.com";
// export const SERVER_URL = "https://test.ukmiko.com";
// export const PEER_IP = "peer.ukmiko.com";
// export const PEER_PORT = "443";
// export const PEER_PATH = "/";
export const SERVER_LINK = process.env.REACT_APP_SERVER_LINK;
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const PEER_IP = process.env.REACT_APP_PEER_IP;
export const PEER_PORT = process.env.REACT_APP_PEER_PORT;
export const PEER_PATH = process.env.REACT_APP_PEER_PATH;
